<template>
  <v-container class="container-max-width py-0 d-flex justify-center align-center">
    <div v-if="isLoading">
      <v-row>
        <v-col class="text-center">
          <loading-syncio-logo></loading-syncio-logo>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row v-if="isError">
        <v-col cols="12" class="d-flex justify-center">
          <div class="text-h3">
            Something went wrong, Please contact Support!!!
          </div>
        </v-col>
      </v-row>
      <div v-else>
        <v-row style="transform: translateX(-6px);">
          <v-col cols="12" class="align-center pb-0">
            <div class="text-center">
              <svg width="120px" height="30px" viewBox="0 0 100 30">
                <circle cx="10" cy="10" r="9" class="filled"/>
                <line x1="19" y1="10" x2="31" y2="10" class="line"/>
                <circle cx="40" cy="10" r="9" class="filled"/>
                <line x1="61" y1="10" x2="49" y2="10" class="line"/>
                <circle cx="70" cy="10" r="9" class="filled"/>
                <line x1="91" y1="10" x2="79" y2="10" class="line"/>
                <circle cx="100" cy="10" r="9" class="filled"/>
              </svg>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-7">
          <v-col cols="12" class="text-center pt-0">
            <p class="text-h8 text-center mb-2 font-weight-bold">STEP 4</p>
            <div class="text-h1 primary--text">
              Installation Complete!
            </div>
            <div class="text-subtitle-2 primary--text mt-2">
              Here are a few guides to help you Sync your first product!
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-12">
          <v-col cols="4">
            <a class="text-decoration-none" href="https://www.notion.so/syncio/Syncing-your-first-product-3b831d7cc38640ffb82393dbdcfaa662" target="_blank">
              <v-card class="px-6 py-9 custom-cards card-rounded">
                <div class="d-flex flex-row justify-space-between">
                  <div class="text-h4">
                    Guide to Syncing
                  </div>
                  <div>
                    <v-icon class="black--text">launch</v-icon>
                  </div>
                </div>
                <div class="mt-4 text-body-2">
                  Read our step by step guide to sync your first product!
                </div>
              </v-card>
            </a>
          </v-col>
          <v-col cols="4">
            <a class="text-decoration-none" href="https://www.notion.so/syncio/Tips-for-a-healthy-sync-8afc765c9b3f476c93f02422ebc59511" target="_blank">
              <v-card class="px-6 py-9 custom-cards card-rounded">
                <div class="d-flex flex-row justify-space-between">
                  <div class="text-h4">
                    Best Practices
                  </div>
                  <div>
                    <v-icon class="black--text">launch</v-icon>
                  </div>
                </div>
                <div class="mt-4 text-body-2">
                  Six steps to ensure your inventory syncs properly.
                </div>
              </v-card>
            </a>
          </v-col>
          <v-col cols="4">
            <a class="text-decoration-none" href="https://www.notion.so/syncio/Use-Cases-8eb098b60bae4aa083e2d04d8c936476" target="_blank">
              <v-card class="px-6 py-9 custom-cards card-rounded">
                <div class="d-flex flex-row justify-space-between">
                  <div class="text-h4">
                    Use Cases
                  </div>
                  <div>
                    <v-icon class="black--text">launch</v-icon>
                  </div>
                </div>
                <div class="mt-4 text-body-2">
                  See how other companies use Syncio!
                </div>
              </v-card>
            </a>
          </v-col>
        </v-row>
        <v-row class="mt-16 pt-2">
          <v-col cols="12" class="d-flex justify-center">
            <v-btn class="primary rounded-lg font-weight-bold" height="48px" width="334px" @click="$router.push('/dashboard')">
              <div class="d-flex flex-row justify-center">
                <div class="text-body-1">
                  Continue to Dashboard
                </div>
                <div class="ml-3">
                  <v-icon>
                    arrow_forward
                  </v-icon>
                </div>
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import LoadingSyncioLogo from "@/views/registration/components/LoadingSyncioLogo";
import {CHECK_AUTH, SAVE_PLAN} from "@/store/actions.type";
import { mapGetters, mapState } from "vuex";
export default {
name: "InstallationComplete",
  components: {LoadingSyncioLogo},
  data(){
    return {
      isLoading: false
    }
  },
  computed : {
    ...mapGetters("auth", ["currentUserID"]),
    ...mapGetters("plan", ["isError"]),
    ...mapState('plan', ['currentActivePlan']),
  },
  created() {
    this.isLoading = true;
    let payload = this.$route.query;
    this.$store.dispatch(`auth/${CHECK_AUTH}`).then(({data}) => {
      if (data.success){
        this.$store.dispatch(`plan/${SAVE_PLAN}`, {
          user_id: this.currentUserID,
          plan_id: payload.plan_id,
          charge_id: payload.charge_id,
          option_ids: payload.addons
        }).then((data) => {
          if (data.success) {
            if (!payload.isonboard){
              this.$router.push('/account/billing');
            }else{
              this.isLoading = false;
            }
          }
        }).catch(() => {
          this.isLoading = false;
        });
      }
    });
  }
}
</script>
<style scoped lang="scss">
.container-max-width{
  width: 1100px;
  min-height: 100%;
}
.custom-cards{
  width: 333px;
  min-height: 155px;
}
</style>